<template>
  <v-dialog v-if="transDialog" v-model="transDialog" :max-width="maxWidth || 515">
    <v-card class="pa-5" :style="{ height: '80vh', overflowY: 'scroll' }">
      <template v-if="isLoading">
        <div class="pb-3">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </template>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-start mb-4">
          <h1 class="text-center flex-grow-1">Transfer Detail</h1>
          <v-btn icon @click="closePopup"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-row>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">TranNbr:</h3>
              <p>{{ transDetail.tranNbr }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">TranStatus:</h3>
              <p>{{ transDetail.tranStatus }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">From Center:</h3>
              <p>{{ transDetail.fromLocationName }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">To Center:</h3>
              <p>{{ transDetail.toLocationName }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">Reason:</h3>
              <p>{{ transDetail.reason | hasValue }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">PO No:</h3>
              <p>{{ transDetail.purOrdID }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">Persion in charge:</h3>
              <p>{{ transDetail.personInCharge | hasValue }}</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <h3 class="mr-3">Receiver:</h3>
              <p>{{ transDetail.receiver | hasValue }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3"><h3>Note:</h3></v-col>
          <v-col cols="9">{{ transDetail.notes }}</v-col>
        </v-row>
      </div>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="filtered"
        :items-per-page="1000000"
        hide-default-header
        hide-default-footer
        class="elevation-0 my-5 bookTable"
        fixed-header
        height="49vh"
        id="bookTable"
      >
        <template v-slot:header="{}">
          <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
            <tr>
              <th
                v-for="(header, index) in headers"
                :key="index"
                :style="{
                  background: '#0b2585',
                  color: '#fff',
                  textAlign: 'center',
                }"
                v-html="header.text"
              ></th>
            </tr>
          </thead>
          <tr class="fixed-book-filter">
            <th v-for="header in headers" :key="header.text">
              <Autocomplete
                v-if="autoFilters.hasOwnProperty(header.value)"
                @setFilter="setAutoFilter"
                :styleBorder="'border-bottom'"
                :keyFilter="header.value"
                :selectedInit="autoFilters[header.value]"
                :listItem="groupColumnValueList(header.value)"
              />
            </th>
          </tr>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="items.length === 0" style="text-align: center">
            <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
              No data available
            </div>
          </tbody>
          <tbody v-for="(item, index) in filtered" :key="index">
            <tr class="row-sale" :key="index">
              <td>
                {{ item.productCode }}
              </td>
              <td style="width: 200px;">
                {{ item.productName }}
              </td>
              <td>
                {{ item.unit }}
              </td>
              <td style="width: 200px;">
                {{ item.fromWarehouseName }}
              </td>
              <td style="width: 200px;">
                {{ item.toWarehouseName  }}
              </td>
              <td>
                {{ item.totQtyOrd | hasValue }}
              </td>
              <td>
                {{ item.qtyOrd }}
              </td>
              <td>
                {{ item.qty }}
              </td>
              <td>
                {{ item.unitCost.toLocaleString() }}
              </td>
              <td>{{ item.tranAmt.toLocaleString() | hasValue }}</td>
            </tr>
          </tbody>
          <tfoot v-if="items.length > 0">
            <tr class="bookTableFoot">
              <td class="text-h6" colspan="7" style="text-align: left !important">Grand Total</td>
              <td>
                {{ filteredPayment.overallTotalQty.toLocaleString() }}
              </td>
              <td>-</td>
              <td>
                {{ filteredPayment.overallAmount.toLocaleString() }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { book_management } from '@/api/book_management'
import ViewDetail from '@/mixins/viewDetail.js'
import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  mixins: [ViewDetail],
  components: { Autocomplete },
  props: {
    height: String,
    maxWidth: String,
    maxHeight: String,
    transDetail: Object,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      dataTable: [],
      transDialog: false,
      isLoading: false,
      autoFilters: {
        productCode: [],
        productName: [],
        unit: [],
        fromWarehouseName: [],
        toWarehouseName : [],
        totQtyOrd: [],
        qtyOrd: [],
        qty: [],
        unitCost: [],
        tranAmt: [],
      },
      //setting table
      headers: [
        {
          text: 'Product Code',
          value: 'productCode',
          sortable: false,
        },
        {
          text: 'Product Name',
          value: 'productName',
          sortable: false,
        },
        {
          text: 'Unit',
          value: 'unit',
          sortable: false,
        },
        {
          text: 'From Whse',
          value: 'fromWarehouseName',
          sortable: false,
        },
        {
          text: 'To Whse',
          value: 'toWarehouseName ',
          sortable: false,
        },
        {
          text: 'Tol Qty Ord',
          value: 'totQtyOrd',
          sortable: false,
        },
        {
          text: 'Qty Ord',
          value: 'qtyOrd',
          sortable: false,
        },
        { text: 'Quantity', value: 'qty', sortable: false },
        {
          text: 'Unit Price',
          value: 'unitCost',
          sortable: false,
        },
        {
          text: 'Amount',
          value: 'tranAmt',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filteredPayment() {
      let grandTotal = {
        overallTotalQty: 0,
        overallAmount: 0,
      }
      this.filtered.forEach(el => {
        grandTotal.overallTotalQty += el.qty
        grandTotal.overallAmount += el.tranAmt
      })
      grandTotal.overallTotalQty = Number(grandTotal.overallTotalQty.toFixed(0))
      grandTotal.overallAmount = Number(grandTotal.overallAmount.toFixed(0))
      return grandTotal
    },
    filtered() {
      return this.dataTable?.filter(d => {
        return Object.keys(this.autoFilters).every(f => {
          return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
        })
      })
    },
  },
  created() {},
  watch: {
    transDialog(val) {
      if (val === false) {
        this.transDialog = false
        this.dataTable = []
      }
    },
  },
  methods: {
    setAutoFilter(objectFilterChange) {
      this.autoFilters = {
        ...this.autoFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      if (val === 'qtyOrd') {
        return [...new Set(this.dataTable.map(d => d[val]))]
      }
      return this.dataTable.map(d => d[val]).filter(y => y)
    },
    async open(id) {
      this.isLoading = true
      this.dataTable = await book_management.getTransferDetailByID(id)
      this.isLoading = false
      this.transDialog = true
    },
    closePopup() {
      this.transDialog = false
      this.isLoading = false
      this.dataTable = []
    },
    // async init() {
    //   this.isLoading = true
    //   await book_management.getTransferDateByID(this.transDetail.tranNbr).then(res => {
    //     this.dataTable = res
    //   })
    // },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 58px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
.row-sale {
  background: #f3f3f3;
  td {
    text-align: center;
  }
}
.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
