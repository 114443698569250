<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="book-inventory mt-5">
    <h1 class="heading">Transfer Search</h1>
    <div class="d-flex mt-4 ml-0" style="gap: 10px">
      <v-autocomplete
        v-model="fromCenter"
        :items="listLocation"
        item-text="location"
        item-value="locationID"
        class="select-item"
        label="From Center"
        outlined
        dense
      ></v-autocomplete>
      <v-autocomplete
        v-model="toCenter"
        :items="listLocation"
        item-text="location"
        item-value="locationID"
        class="select-item"
        label="To Center"
        outlined
        dense
      ></v-autocomplete>
      <v-text-field
        type="date"
        v-model.lazy="fromDate"
        label="From date"
        class="select-item"
        outlined
        dense
        hide-details
      />
      <v-text-field
        type="date"
        v-model.lazy="toDate"
        label="To date"
        class="select-item"
        outlined
        dense
        hide-details
      />
      <v-autocomplete
        v-model="tranStatus"
        :items="statusList"
        item-text="status"
        item-value="status"
        class="select-item"
        label="Tran. Status"
        outlined
        dense
      ></v-autocomplete>
      <v-autocomplete
        v-model="reasonID"
        :items="reasonList"
        item-text="reason"
        item-value="reasonID"
        class="select-item"
        label="Reason"
        outlined
        dense
      ></v-autocomplete>
      <v-text-field
        type="text"
        v-model.lazy="purOrdId"
        label="PO No."
        class="select-item"
        outlined
        dense
        hide-details
      />
      <v-btn color="primary" @click="SearchData">Search</v-btn>
    </div>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <h3 class="mb-3" v-if="!isLoading">Total: {{ this.filtered.length }} transfer orders</h3>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="filtered"
      :items-per-page="1000000"
      hide-default-header
      hide-default-footer
      class="elevation-0 mb-5 bookTable"
      fixed-header
      height="69vh"
      id="bookTable"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :style="{
                background: '#0b2585',
                color: '#fff',
                textAlign: 'center',
                width: `${header.width}`,
                maxWidth: `${header.width}`,
              }"
              v-html="header.text"
            ></th>
          </tr>
        </thead>
        <tr class="fixed-book-filter">
          <th v-for="header in headers" :key="header.text">
            <AutoFilter
              v-if="autoFilters.hasOwnProperty(header.value)"
              @setFilter="setAutoFilter"
              :styleBorder="'border-bottom'"
              :keyFilter="header.value"
              :selectedInit="autoFilters[header.value]"
              :listItem="groupColumnValueList(header.value)"
            />
          </th>
        </tr>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length === 0" style="text-align: center">
          <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
            No data available
          </div>
        </tbody>
        <tbody v-for="(item, index) in filtered" :key="index">
          <tr class="row-sale" :key="index">
            <td>
              {{ item.tranNbr }}
            </td>
            <td>
              {{ _dayjs(item.tranDate).format('DD/MM/YYYY') }}
            </td>
            <td>
              {{ item.period }}
            </td>
            <td>
              {{ item.tranStatus }}
            </td>
            <td>
              {{ item.purOrdID }}
            </td>
            <td>
              {{ item.fromLocationName | hasValue }}
            </td>
            <td>
              {{ item.toLocationName  | hasValue}}
            </td>
            <td>
              {{ item.totQty }}
            </td>
            <td>
              {{ item.totAmt.toLocaleString() }}
            </td>
            <td>{{ item.notes | hasValue }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 mr-2 btn-pointer"
                    @click=";(selectedTransData = item), $refs.viewDetailTest.open(item.tranNbr)"
                    :src="require('../../assets/images/icon-eye.svg')"
                    alt=""
                    srcset=""
                  />
                </template>
                <span>View Detail</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr class="bookTableFoot">
            <td class="text-h6" colspan="7" style="text-align: left !important">Grand Total</td>
            <td>
              {{ filteredPayment.overallTotalQty.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallAmount.toLocaleString() }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
    <TransferDataDetail
      maxWidth="60vw"
      maxHeight="80vh"
      :transDetail="selectedTransData"
      ref="viewDetailTest"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { book_management } from '@/api/book_management'
import TransferDataDetail from './TransferDataDetail.vue'
// import { requestOptions } from '@/api/request-options'
/**
 * * import custom filter for table
 */
import AutoFilter from '@/components/Utils/Autocomplete.vue'
/**
 * ! import mixins for filter
 */
import ViewDetail from '@/mixins/viewDetail.js'

export default {
  components: {
    AutoFilter,
    TransferDataDetail
  },
  mixins: [ViewDetail],
  data() {
    return {
      isLoading: false,
      header: {},
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      fromDate: new Date(dayjs()),
      toDate: new Date(dayjs()),
      fromCenter: null,
      toCenter: null,
      tranStatus: 'All',
      reasonID: 0,
      purOrdId: 0,
      listLocation: [],
      selectedTransData: {},
      statusList: [
        {
          statusID: 0,
          status: 'All',
        },
        {
          statusID: 1,
          status: 'Hold',
        },
        {
          statusID: 2,
          status: 'Released',
        },
      ],
      reasonList: [
        {
          reasonID: 0,
          reason: 'All',
        },
        {
          reasonID: 1,
          reason: 'PO Transfer',
        },
        {
          reasonID: 2,
          reason: 'Inventory movement',
        },
      ],
      listInventory: [],

      //
      autoFilters: {
        tranNbr: [],
        tranDate: [],
        period: [],
        tranStatus: [],
        purOrdID: [],
        fromLocationName: [],
        toLocationName: [],
        totQty: [],
        totAmt: [],
        notes: [],
      },
      //setting table
      headers: [
        { text: 'TranNbr', align: 'center', value: 'tranNbr', width: '100px', sortable: false },
        {
          text: 'Tran.Date',
          value: 'tranDate',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Period',
          value: 'period',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Tran. Status',
          value: 'tranStatus',
          align: 'center',
          width: '50px',
          sortable: false,
        },
        {
          text: 'PO Number',
          value: 'purOrdID',
          align: 'center',
          width: '50px',
          sortable: false,
        },
        {
          text: 'From Center',
          value: 'fromLocationName',
          align: 'center',
          width: '200px',
          sortable: false,
        },
        {
          text: 'To Center',
          value: 'toLocationName',
          align: 'center',
          width: '200px',
          sortable: false,
        },
        { text: 'Total Qty', value: 'totQty', align: 'center', width: '100px', sortable: false },
        {
          text: 'Total Amount',
          value: 'totAmt',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Note',
          value: 'notes',
          width: '400px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          width: '80px',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filteredPayment() {
      let grandTotal = {
        overallTotalQty: 0,
        overallAmount: 0,
      }
      this.filtered.forEach(el => {
        grandTotal.overallTotalQty += el.totQty
        grandTotal.overallAmount += el.totAmt
      })

      grandTotal.overallTotalQty = Number(grandTotal.overallTotalQty.toFixed(0))
      grandTotal.overallAmount = Number(grandTotal.overallAmount.toFixed(0))
      return grandTotal
    },
    filtered() {
      return this.listInventory?.filter(d => {
        return Object.keys(this.autoFilters).every(f => {
          return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
        })
      })
    },
  },
  created() {
    this.getCenter()
    this.getFromToDate()
    this.init()
  },
  methods: {
    SearchData() {
      this.init()
    },
    changeHiddenSup(subItem) {
      if (this.listHidden.includes(subItem)) {
        this.listHidden = this.listHidden.filter(function (item) {
          return item !== subItem
        })
      } else {
        this.listHidden = [...this.listHidden, subItem]
      }
    },
    /**
     * ? config filter
     */
    setAutoFilter(objectFilterChange) {
      this.autoFilters = {
        ...this.autoFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      if (val === 'purOrdID') {
        return [...new Set(this.listInventory.map(d => d[val]))]
      }
      return this.listInventory.map(d => d[val]).filter(y => y)
    },
    /**
     * ? config center autocomplete
     */
    getCenter() {
      const centers = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
      this.listLocation = [{ locationID: null, location: 'All Center' }, ...centers]
    },
    getIdArray(arr1, arr2, name, id) {
      return arr1.filter(item => arr2.includes(item[name])).map(item => item[id])
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },

    /**
     * ? Configure set month and Year
     */
     getFromToDate() {
      this.fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 2)
        .toISOString()
        .split('T')[0]
      this.toDate = this.getLastDayOfMonth()
    },
    getLastDayOfMonth() {
      const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
      const formattedLastDay = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
        lastDay
      ).padStart(2, '0')}`
      return formattedLastDay
    },
    async init() {
      this.isLoading = true
      //   await book_management.getTransferDate(
      //     null,
      //     null,
      //     this.fromDate,
      //     this.toDate,
      //     this.tranStatus,
      //     this.reasonID,
      //     this.purOrdId,
      //   ).then(res=>{
      //     this.listInventory = res
      //     this.isLoading = false
      //   })
      await book_management
        .getTransferDate(
          this.fromCenter,
          this.toCenter,
          this.fromDate,
          this.toDate,
          this.tranStatus,
          this.reasonID,
          this.purOrdId
        )
        .then(res => {
          this.listInventory = res
          this.isLoading = false
        })
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 48px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
.row-sale {
  background: #f3f3f3;
  td {
    text-align: center;
  }
}
.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
